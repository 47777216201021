import { combineReducers } from "redux";

import searchResults from "./searchResultsReducer";
import searched from "./searchedReducer";

const rootReducer = combineReducers({
  searchResults,
  searched,
});

export default rootReducer;
