import React, { useState, FormEvent } from "react";
import SearchBar from "../components/SearchBar";
import { withRouter, RouteComponentProps } from "react-router-dom";
import qs from "qs";

export interface searchObject {
  query: string;
  sort: string;
  type: string;
}

type Props = {
  showAddToDirectory?: boolean;
  showFilters?: boolean;
} & RouteComponentProps;

const SearchBarContainer = (props: Props) => {
  const { showAddToDirectory, showFilters, history, location } = props;
  const [searchObj, setSearchObj] = useState<searchObject>({
    query: qs.parse(location.search, { ignoreQueryPrefix: true }).query
      ? qs.parse(location.search, { ignoreQueryPrefix: true }).query.toString()
      : "",
    sort: qs.parse(location.search, { ignoreQueryPrefix: true }).sort
      ? qs.parse(location.search, { ignoreQueryPrefix: true }).sort.toString()
      : "Relevance",
    type: qs.parse(location.search, { ignoreQueryPrefix: true }).type
      ? qs.parse(location.search, { ignoreQueryPrefix: true }).type.toString()
      : "Any",
  });

  const setQuery = (q: string) => {
    let tempObj = searchObj;
    tempObj.query = q;
    setSearchObj(tempObj);
  };

  const setSort = (sort: string) => {
    let tempObj = searchObj;
    tempObj.sort = sort;
    setSearchObj(tempObj);
    handleSearch();
  };

  const setType = (type: string) => {
    let tempObj = searchObj;
    tempObj.type = type;
    setSearchObj(tempObj);
    handleSearch();
  };

  const handleSearch = (event?: FormEvent) => {
    if (event) event.preventDefault();
    history.push({
      pathname: "search",
      search: qs.stringify(searchObj),
    });
  };

  return (
    <SearchBar
      showAddToDirectory={showAddToDirectory}
      showFilters={showFilters}
      setQuery={setQuery}
      setSort={setSort}
      setType={setType}
      onSubmit={handleSearch}
      sort={searchObj.sort}
      type={searchObj.type}
    />
  );
};

export default withRouter(SearchBarContainer);
