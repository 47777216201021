import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const AddToDirectoryDialog = (props: Props) => {
  const { show, handleClose } = props;

  return (
    <Modal show={show} onHide={handleClose} keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontWeight: 600 }}>Organization Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          variant="success"
          size="sm"
          block
          href={"//owlwise.com/register/school"}
        >
          I want to list my school
        </Button>
        <Button
          variant="primary"
          size="sm"
          block
          href={"//owlwise.com/register/club"}
        >
          I want to list my club
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddToDirectoryDialog;
