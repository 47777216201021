import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Box from "@material-ui/core/Box";
import Button from "react-bootstrap/esm/Button";

type Props = {
  name: string;
  shortName: string;
  shortDescription: string;
  type: string;
  city: string;
  state_province: string;
  onCardImageLoad?: () => void;
};

const SearchResultCard = (props: Props) => {
  const {
    name,
    shortName,
    shortDescription,
    type,
    city,
    state_province,
    onCardImageLoad,
  } = props;
  return (
    <Card
      style={{ width: "100%" }}
      className={type === "school" ? "school-card" : "club-card"}
    >
      <a
        href={"//" + shortName + ".owlwise.com"}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <Card.Body>
          <Row>
            <Col xs="2" className="my-auto">
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="4%"
                style={{ width: 120, height: 120, padding: 5 }}
              >
                <Card.Img
                  variant="top"
                  src={
                    "https://" +
                    shortName +
                    ".owlwise.com/api/logo/" +
                    shortName
                  }
                  alt={name + " logo"}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  {...(onCardImageLoad && {
                    onLoad: () => onCardImageLoad(),
                    onError: () => onCardImageLoad(),
                  })}
                />
              </Box>
            </Col>
            <Col xs="8">
              <Card.Title style={{ fontSize: 18, fontWeight: 600 }}>
                {name}
              </Card.Title>
              <Card.Subtitle
                className={
                  type === "school"
                    ? "school-color"
                    : type === "club"
                    ? "club-color"
                    : undefined
                }
              >
                <strong>
                  {type ? type.charAt(0).toUpperCase() + type.slice(1) : ""}
                </strong>
              </Card.Subtitle>
              <Card.Text style={{ fontSize: 13, color: "grey" }}>
                {city && state_province
                  ? city + ", " + state_province.toUpperCase()
                  : ""}
              </Card.Text>
              <Card.Text style={{ fontSize: 14 }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: shortDescription
                      ? shortDescription.length > 500
                        ? shortDescription.substring(0, 497) + "..."
                        : shortDescription
                      : "",
                  }}
                ></div>
              </Card.Text>
            </Col>
            <Col xs="auto" className="my-auto" style={{ marginLeft: 40 }}>
              <Button
                variant="success"
                href={"//" + shortName + ".owlwise.com/register/ask"}
                style={{ fontSize: 14 }}
              >
                Register
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </a>
    </Card>
  );
};

export default SearchResultCard;
