import * as actionTypes from "../actionTypes";

const initialState = {
  results: {},
};

const searchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_RESULTS:
      return action.payload;
    default:
      return state;
  }
};

export default searchResultsReducer;
