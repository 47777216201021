import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import SearchBarContainer from "../containers/SearchBarContainer";

const Header = () => {
  return (
    <Jumbotron fluid style={{ backgroundColor: "transparent" }}>
      <h1 style={{ fontWeight: 600, fontSize: 55 }}>Owlwise Search</h1>
      <SearchBarContainer showAddToDirectory={true} showFilters={false} />
    </Jumbotron>
  );
};

export default Header;
