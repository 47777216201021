import React from "react";
import Featured from "../components/Featured";
import { useFetch } from "../utils/useFetch";

const FeaturedContainer = () => {
  const { data, loading } = useFetch("/featured");

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <h1 style={{ fontWeight: 600 }}>Featured</h1>
          <Featured featured={data} />
        </>
      )}
    </>
  );
};

export default FeaturedContainer;
