import { useState, useEffect, useRef } from "react";

export const useFetch = (path: string, options?: object) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const hasPathChanged = useHasChanged(path);
  const hasOptionsChanged = useHasChanged(options);

  useEffect(() => {
    if (hasPathChanged || hasOptionsChanged) {
      const fetchData = async () => {
        const response = await fetch(window.location.origin + path, options);
        const data = await response.json();

        setData(data);
        setLoading(false);
      };

      fetchData();
    }
  }, [path, options, hasPathChanged, hasOptionsChanged]);

  return { data, loading };
};

const useHasChanged = (val: any) => {
  const prevVal = usePrevious(val);
  if (typeof prevVal === "object" && typeof val === "object") {
    return JSON.stringify(prevVal) !== JSON.stringify(val);
  }
  return prevVal !== val;
};

const usePrevious = (val: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = val;
  });
  return ref.current;
};
