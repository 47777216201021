import React from "react";
import Navbar from "./NavBar";
import SearchBarContainer from "../containers/SearchBarContainer";
import SearchResultsContainer from "../containers/SearchResultsContainer";
import { useFetch } from "../utils/useFetch";
import { withRouter, RouteComponentProps } from "react-router-dom";
import qs from "qs";

const Search = (props: RouteComponentProps) => {
  const { location } = props;
  const { data } = useFetch("/search", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      qs.parse(location.search, { ignoreQueryPrefix: true })
    ),
  });

  return (
    <div>
      <Navbar />
      <SearchBarContainer showAddToDirectory={true} showFilters={true} />
      <br />
      <SearchResultsContainer
        searchResults={data}
        searchBody={qs.parse(location.search, { ignoreQueryPrefix: true })}
      />
    </div>
  );
};

export default withRouter(Search);
