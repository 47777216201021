import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Box from "@material-ui/core/Box";

type Props = {
  name?: string;
  shortName?: string;
  shortDescription?: string;
  type?: string;
  invisible?: Boolean;
};

const visibleStyle = {
  width: "18rem",
  padding: 5,
  marginBottom: 15,
};

const invisibleStyle = {
  width: "18rem",
  padding: 5,
  marginBottom: 15,
  border: 0,
};

const FeaturedCard = (props: Props) => {
  const { name, shortName, shortDescription, type, invisible } = props;

  return (
    <>
      {invisible ? (
        <Card style={invisibleStyle} />
      ) : (
        <Card
          style={visibleStyle}
          className={type === "school" ? "school-card" : "club-card"}
        >
          <a
            href={"//" + shortName + ".owlwise.com"}
            style={{ textDecoration: "none", color: "#000" }}
          >
            <Card.Body>
              <Row>
                <Col xs="auto" className="my-auto">
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="4%"
                    style={{ width: 80, height: 80, padding: 10 }}
                  >
                    <Card.Img
                      variant="top"
                      src={
                        "https://" +
                        shortName +
                        ".owlwise.com/api/logo/" +
                        shortName
                      }
                      alt={name + " logo"}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Col>
                <Col xs="8">
                  <Card.Title style={{ fontSize: 16, fontWeight: 600 }}>
                    {name}
                  </Card.Title>
                  <Card.Text style={{ fontSize: 12.5 }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: shortDescription
                          ? shortDescription.length > 150
                            ? shortDescription.substring(0, 147) + "..."
                            : shortDescription
                          : "",
                      }}
                    ></div>
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </a>
        </Card>
      )}
    </>
  );
};

export default FeaturedCard;
